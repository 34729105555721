import { Actions } from "../../helpers/resource_helper";
import {
  ADD_SERVICE,
  ADD_SERVICE_ERROR,
  ADD_SERVICE_SUCCESS,
  DELETE_SERVICE,
  DELETE_SERVICE_ERROR,
  DELETE_SERVICE_SUCCESS,
  GET_SERVICE,
  GET_SERVICE_ERROR,
  GET_SERVICE_SUCCESS,
  LIST_SERVICE,
  LIST_SERVICE_ERROR,
  LIST_SERVICE_SUCCESS,
  RESET_SERVICE,
  UPDATE_SERVICE,
  UPDATE_SERVICE_ERROR,
  UPDATE_SERVICE_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  services: [],
  service: {},
  error: null,
  success: false,
  loading: false,
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Service = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_SERVICE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        services: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_SERVICE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_SERVICE:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        service: action.payload,
        action: Actions.EMPTY,
      };

    case GET_SERVICE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_SERVICE:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        services: state.services.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_SERVICE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_SERVICE:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_SERVICE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        services: [...state.services, action.payload],
        service: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_SERVICE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_SERVICE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        services: state.services.map((service) =>
          service.id.toString() === action.payload.id.toString() ? { ...service, ...action.payload } : service,
        ),
        service: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_SERVICE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_SERVICE:
      return {
        ...state,
        service: {},
        loading: false,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default Service;
