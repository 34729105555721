import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const createMemoizedSelector = (selectorFunction) => {
  return createSelector([selectorFunction], (result) => result);
};

const useMemoizedSelector = (selectorFunction) => {
  const memoizedSelector = createMemoizedSelector(selectorFunction);
  return useSelector(memoizedSelector);
};

export default useMemoizedSelector;
