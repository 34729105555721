import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    const { t } = useTranslation();

    return <Component {...props} router={{ location, navigate, params, t }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter;
