export const supported_permissions = {
  order: {
    _list: {
      description: "Xem danh sách đơn hàng",
      active: false,
    },
    _list_by_customer_id: {
      description: "Xem danh sách đơn hàng theo mã khách hàng",
      active: false,
    },
    get: {
      description: "Xem chi tiết đơn hàng",
      active: false,
    },
    add: {
      description: "Thêm đơn hàng",
      active: false,
    },
    update: {
      description: "Sửa đơn hàng",
      active: false,
    },
    delete: {
      description: "Xóa đơn đơn hàng",
      active: false,
    },
    confirm_api: {
      description: "Xác nhận trạng thái đơn hàng",
      active: false,
    },
    pay_api: {
      description: "Xác nhận trạng thái thanh toán đơn hàng",
      active: false,
    },
    pay_pos_api: {
      description: "Thanh toán và hoàn thành đơn hàng",
      active: false,
    },
    request_cancel_api: {
      description: "Xác nhận trạng thái hủy một phần đơn hàng",
      active: false,
    },
    pack_api: {
      description: "Xác nhận trạng thái đóng gói đơn hàng và tạo gói hàng",
      active: false,
    },
    order_process: {
      description: "Quản lý trạng thái đơn hàng",
      active: false,
    },
  },
  return_order: {
    _list: {
      description: "Xem danh sách đơn trả hàng",
      active: false,
    },
    get: {
      description: "Xem chi tiết đơn trả hàng",
      active: false,
    },
    add: {
      description: "Thêm đơn trả hàng",
      active: false,
    },
    pay_api: {
      description: "Thanh toán đơn trả hàng",
      active: false,
    },
    receive_api: {
      description: "Nhận đơn trả hàng",
      active: false,
    },
    cancel_api: {
      description: "Huỷ đơn trả hàng",
      active: false,
    },
  },
  stock_adjustment: {
    _list: {
      description: "Xem danh sách phiếu điều chỉnh kho",
      active: false,
    },
    get: {
      description: "Xem chi tiết phiếu điều chỉnh kho",
      active: false,
    },
    add: {
      description: "Thêm phiếu điều chỉnh kho",
      active: false,
    },
    update: {
      description: "Sửa phiếu điều chỉnh kho",
      active: false,
    },
    balance: {
      description: "Xác nhận trạng thái đã cân bằng cho phiếu điều chỉnh kho",
      active: false,
    },
    delete: {
      description: "Xác nhận trạng thái đã xoá cho phiếu điều chỉnh kho",
      active: false,
    },
  },
  stock_relocate: {
    _list: {
      description: "Xem danh sách phiếu chuyển kho",
      active: false,
    },
    get: {
      description: "Xem chi tiết phiếu chuyển kho",
      active: false,
    },
    add: {
      description: "Thêm phiếu chuyển kho",
      active: false,
    },
    update: {
      description: "Sửa phiếu chuyển kho",
      active: false,
    },
    delivery: {
      description: "Xác nhận trạng thái đang vận chuyển cho phiếu chuyển kho",
      active: false,
    },
    receive: {
      description: "Xác nhận trạng thái đã nhận cho phiếu chuyển kho",
      active: false,
    },
    cancel: {
      description: "Xác nhận trạng thái đã huỷ cho phiếu chuyển kho",
      active: false,
    },
  },
  package: {
    _list: {
      description: "Xem danh sách gói hàng",
      active: false,
    },
    get: {
      description: "Xem chi tiết gói hàng",
      active: false,
    },
    update: {
      description: "Chỉnh sửa gói hàng",
      active: false,
    },
    sync: {
      description: "Đồng bộ gói hàng",
      active: false,
    },
    cancel_api: {
      description: "Xác nhận trạng thái hủy gói hàng",
      active: false,
    },
    await_packing_api: {
      description: "Xác nhận trạng thái chờ đóng gói hàng",
      active: false,
    },
    pack_api: {
      description: "Xác nhận trạng thái đóng gói hàng",
      active: false,
    },
    ready_api: {
      description: "Xác nhận trạng thái đã gói hàng",
      active: false,
    },
    ship_api: {
      description: "Xác nhận trạng thái đang giao gói hàng",
      active: false,
    },
    delivered_api: {
      description: "Xác nhận trạng thái đã gói hàng",
      active: false,
    },
    return_api: {
      description: "Xác nhận trạng thái trả gói hàng",
      active: false,
    },
    delivered_return_api: {
      description: "Xác nhận trạng thái đã trả gói hàng",
      active: false,
    },
    blocking_api: {
      description: "Xác nhận trạng thái chờ giải quyết gói hàng",
      active: false,
    },
    system_receive_package: {
      description: "Xác nhận trạng thái đã nhận hàng gói hàng",
      active: false,
    },
    assign_staff_package: {
      description: "Gán nhân viên cho gói hàng",
      active: false,
    },
  },
  product: {
    _list: {
      description: "Xem danh sách sản phẩm",
      active: false,
    },
    list_tags: {
      description: "Xem danh sách thẻ của sản phẩm",
      active: false,
    },
    get: {
      description: "Xem chi tiết sản phẩm",
      active: false,
    },
    get_by_slug: {
      description: "Xem chi tiết sản phẩm theo slug",
      active: false,
    },
    add: {
      description: "Thêm sản phẩm",
      active: false,
    },
    update: {
      description: "Sửa sản phẩm",
      active: false,
    },
    delete: {
      description: "Xóa sản phẩm",
      active: false,
    },
  },
  variant: {
    _list: {
      description: "Xem danh sách biến thể sản phẩm",
      active: false,
    },
    get: {
      description: "Xem chi tiết biến thể sản phẩm",
      active: false,
    },
    get_by_slug: {
      description: "Xem chi tiết biến thể sản phẩm theo slug",
      active: false,
    },
    add: {
      description: "Thêm biến thể sản phẩm",
      active: false,
    },
    update: {
      description: "Sửa biến thể sản phẩm",
      active: false,
    },
    delete: {
      description: "Xóa biến thể sản phẩm",
      active: false,
    },
  },
  brand: {
    _list: {
      description: "Xem danh sách thương hiệu",
      active: false,
    },
    get: {
      description: "Xem chi thương hiệu",
      active: false,
    },
    add: {
      description: "Thêm thương hiệu",
      active: false,
    },
    update: {
      description: "Sửa thương hiệu",
      active: false,
    },
    delete: {
      description: "Xóa thương hiệu",
      active: false,
    },
  },
  category: {
    _list: {
      description: "Xem danh sách danh mục",
      active: false,
    },
    get: {
      description: "Xem chi chi tiết danh mục",
      active: false,
    },
    get_by_slug: {
      description: "Xem chi chi tiết danh mục theo slug",
      active: false,
    },
    add: {
      description: "Thêm danh mục",
      active: false,
    },
    update: {
      description: "Sửa danh mục",
      active: false,
    },
    delete: {
      description: "Xóa danh mục",
      active: false,
    },
  },
  integration: {
    _list: {
      description: "Xem danh sách kênh bán hàng",
      active: false,
    },
    add: {
      description: "Thêm kênh bán hàng",
      active: false,
    },
    update: {
      description: "Cài đặt kênh bán hàng",
      active: false,
    },
    delete: {
      description: "Xóa kênh bán hàng",
      active: false,
    },
  },
  shipping_provider: {
    _list: {
      description: "Xem danh sách nhà vận chuyển",
      active: false,
    },
    add: {
      description: "Thêm nhà vận chuyển",
      active: false,
    },
    update: {
      description: "Sửa nhà vận chuyển",
      active: false,
    },
    delete: {
      description: "Xóa nhà vận chuyển",
      active: false,
    },
  },
  location: {
    _list: {
      description: "Xem danh sách cửa hàng",
      active: false,
    },
    get: {
      description: "Xem chi tiết danh mục",
      active: false,
    },
    add: {
      description: "Thêm cửa hàng",
      active: false,
    },
    update: {
      description: "Sửa thông tin cửa hàng",
      active: false,
    },
    delete: {
      description: "Xóa thông tin cửa hàng",
      active: false,
    },
  },
  discount: {
    _list: {
      description: "Xem danh sách chiết khấu",
      active: false,
    },
    get: {
      description: "Xem chi tiết chiết khấu",
      active: false,
    },
    add: {
      description: "Thêm chiết khấu",
      active: false,
    },
    update: {
      description: "Sửa thông tin chiết khấu",
      active: false,
    },
    delete: {
      description: "Xóa chiết khấu",
      active: false,
    },
  },
  voucher: {
    _list: {
      description: "Xem danh sách đợt phát hành mã",
      active: false,
    },
    get: {
      description: "Xem chi tiết đợt phát hành mã",
      active: false,
    },
    add: {
      description: "Thêm đợt phát hành mã",
      active: false,
    },
    update: {
      description: "Sửa thông tin đợt phát hành mã",
      active: false,
    },
    update_status: {
      description: "Thay đổi trạng thái đợt phát hành mã",
      active: false,
    },
  },
  voucher_code: {
    _list: {
      description: "Xem danh sách mã giảm giá",
      active: false,
    },
    get: {
      description: "Xem chi tiết mã giảm giá",
      active: false,
    },
    add: {
      description: "Thêm mã giảm giá",
      active: false,
    },
  },
  settings: {
    shop_info: {
      description: "Quản lý thông tin cửa hàng",
      active: false,
    },
    price_groups: {
      description: "Quản lý giá của hàng",
      active: false,
    },
    prints: {
      description: "Quản lý mẫu in",
      active: false,
    },
    accounts: {
      description: "Quản lý nhân viên và phân quyền",
      active: false,
    },
    theme_color: {
      description: "Quản lý màu chủ đề",
      active: false,
    },
    activities: {
      description: "Quản lý lịch sử",
      active: false,
    },
  },
  purchase_orders: {
    _list: {
      description: "Xem danh sách đơn đặt hàng",
      active: false,
    },
    get: {
      description: "Xem chi tiết đơn đặt hàng",
      active: false,
    },
    add: {
      description: "Thêm đơn đặt hàng",
      active: false,
    },
    pay: {
      description: "Thanh toán đơn đặt hàng",
      active: false,
    },
    _pay: {
      description: "Thanh toán một phần đơn đặt hàng",
      active: false,
    },
    cancel: {
      description: "Huỷ đơn đặt hàng",
      active: false,
    },
    return_products: {
      description: "Trả đơn đặt hàng",
      active: false,
    },
    complete: {
      description: "Hoàn thành đơn đặt hàng",
      active: false,
    },
  },
  supplier: {
    _list: {
      description: "Xem danh sách nhà cung cấp",
      active: false,
    },
    get: {
      description: "Xem chi tiết nhà cung cấp",
      active: false,
    },
    add: {
      description: "Thêm nhà cung cấp",
      active: false,
    },
    update: {
      description: "Sửa nhà cung cấp",
      active: false,
    },
  },
  customer: {
    dashboard: {
      description: "Xem tổng quan người dùng",
      active: false,
    },
    _list: {
      description: "Xem danh sách người dùng",
      active: false,
    },
    get: {
      description: "Xem chi tiết người dùng",
      active: false,
    },
    add: {
      description: "Thêm người dùng",
      active: false,
    },
    update: {
      description: "Sửa người dùng",
      active: false,
    },
    delete: {
      description: "Xóa người dùng",
      active: false,
    },
  },
  customer_group: {
    _list: {
      description: "Xem danh sách nhóm người dùng",
      active: false,
    },
    get: {
      description: "Xem chi tiết nhóm người dùng",
      active: false,
    },
    add: {
      description: "Thêm nhóm người dùng",
      active: false,
    },
    update: {
      description: "Sửa nhóm người dùng",
      active: false,
    },
    delete: {
      description: "Xóa nhóm người dùng",
      active: false,
    },
  },
  import: {
    import_list: {
      description: "Quản lý nhập",
      active: false,
    },
  },
  record: {
    list_import_record: {
      description: "Quản lý nhập ghi",
      active: false,
    },
    record_details: {
      description: "Chi tiết nhập ghi",
      active: false,
    },
  },
  payment_methods: {
    _list: {
      description: "Xem danh sách thanh toán",
      active: false,
    },
    get: {
      description: "Xem chi tiết thanh toán",
      active: false,
    },
    add: {
      description: "Thêm thanh toán",
      active: false,
    },
    update: {
      description: "Sửa thanh toán",
      active: false,
    },
    delete: {
      description: "Xóa thanh toán",
      active: false,
    },
  },
  transaction: {
    _list: {
      description: "Xem danh sách giao dịch",
      active: false,
    },
    get: {
      description: "Xem chi tiết giao dịch",
      active: false,
    },
    add: {
      description: "Thêm giao dịch",
      active: false,
    },
    update: {
      description: "Sửa giao dịch",
      active: false,
    },
    delete: {
      description: "Xóa giao dịch",
      active: false,
    },
  },
  account: {
    _list: {
      description: "Xem danh sách tài khoản",
      active: false,
    },
    get: {
      description: "Xem chi tiết tài khoản",
      active: false,
    },
    add: {
      description: "Thêm tài khoản",
      active: false,
    },
    update: {
      description: "Sửa tài khoản",
      active: false,
    },
    delete: {
      description: "Xóa thanh toán",
      active: false,
    },
  },
  report: {
    product_report_list: {
      description: "Xem báo cáo sản phẩm",
      active: false,
    },
  },
  inventory_item: {
    _list: {
      description: "Xem danh sách kho",
      active: false,
    },
    get: {
      description: "Xem chi tiết kho",
      active: false,
    },
    add: {
      description: "Thêm kho",
      active: false,
    },
    update: {
      description: "Sửa kho",
      active: false,
    },
  },
  blog: {
    _list: {
      description: "Xem danh sách bài viết",
      active: false,
    },
    get: {
      description: "Xem chi tiết bài viết",
      active: false,
    },
    add: {
      description: "Thêm bài viết",
      active: false,
    },
    update: {
      description: "Sửa bài viết",
      active: false,
    },
    delete: {
      description: "Xóa bài viết",
      active: false,
    },
  },
  locale_blog: {
    _list: {
      description: "Xem danh sách bài viết",
      active: false,
    },
    get: {
      description: "Xem chi tiết bài viết",
      active: false,
    },
    add: {
      description: "Thêm bài viết",
      active: false,
    },
    update: {
      description: "Sửa bài viết",
      active: false,
    },
    delete: {
      description: "Xóa bài viết",
      active: false,
    },
  },
  blog_category: {
    _list: {
      description: "Xem danh sách danh mục bài viết",
      active: false,
    },
    get: {
      description: "Xem chi tiết danh mục bài viết",
      active: false,
    },
    add: {
      description: "Thêm danh mục bài viết",
      active: false,
    },
    update: {
      description: "Sửa danh mục bài viết",
      active: false,
    },
    delete: {
      description: "Xóa danh mục bài viết",
      active: false,
    },
  },
  terminal: {
    _list: {
      description: "Xem danh sách terminal",
      active: false,
    },
    get: {
      description: "Xem chi tiết terminal",
      active: false,
    },
    add: {
      description: "Thêm terminal",
      active: false,
    },
    update: {
      description: "Sửa terminal",
      active: false,
    },
    delete: {
      description: "Xóa terminal",
      active: false,
    },
  },
  shift: {
    _list: {
      description: "Xem danh sách ca làm việc",
      active: false,
    },
    get: {
      description: "Xem chi tiết ca làm việc",
      active: false,
    },
    add: {
      description: "Thêm ca làm việc",
      active: false,
    },
    update: {
      description: "Sửa ca làm việc",
      active: false,
    },
    close_shift: {
      description: "Đóng ca làm việc",
      active: false,
    },
    check_opened_shift: {
      description: "Kiểm tra ca làm việc đang hoạt động",
      active: false,
    },
  },
  reward: {
    _list: {
      description: "Xem danh sách phần thưởng",
      active: false,
    },
    get: {
      description: "Xem chi tiết phần thưởng",
      active: false,
    },
    add: {
      description: "Thêm phần thưởng",
      active: false,
    },
    update: {
      description: "Sửa phần thưởng",
      active: false,
    },
    delete: {
      description: "Xóa phần thưởng",
      active: false,
    },
  },
  redeem: {
    _list: {
      description: "Xem danh sách đổi thưởng",
      active: false,
    },
    get: {
      description: "Xem chi tiết đổi thưởng",
      active: false,
    },
    add: {
      description: "Thêm đổi thưởng",
      active: false,
    },
    update: {
      description: "Sửa đổi thưởng",
      active: false,
    },
    delete: {
      description: "Xóa đổi thưởng",
      active: false,
    },
  },
  pos: {
    pos: {
      description: "Giao diện POS",
      active: false,
    },
  },
  loyalty_app: {
    settings: {
      description: "Cấu hình cho loyalty app",
      active: false,
    },
  },
};
