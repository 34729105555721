import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addService, deleteService, getService, resetService, updateService } from "../../../store/actions";
import ContentPageTheme from "../../../Components/Common/ContentPageTheme";
import SimpleBar from "simplebar-react";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../Components/Hooks/useMemoizedSelector";
import withRouter from "../../../Components/Common/withRouter";
import { supported_permissions } from "../../../Components/constants";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Container,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { FieldArray, Form, Formik } from "formik";
import InputTheme from "../../../Components/Common/InputTheme";
import ScrollToError from "../../../Components/Common/ScrollToError";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";
import { servicedApi } from "../../../helpers/resource_helper";
import { toast } from "react-toastify";
import UploadTheme from "../../../Components/Common/UploadTheme";
import { cloneObjectOrArray, convertArrToObject, deepCopy, objectToArray } from "../../../common/constants";
import BreadCrumbCustom from "../../../Components/Common/BreadCrumbCustom";
import StatusModal from "../../../Components/Common/StatusModal";
import ModalSubscription from "../Components/ModalSubscription";

const ServiceDetails = (props) => {
  const {
    router: { location, navigate, params, t },
  } = props;
  const dispatch = useDispatch();

  const { id, action } = params;

  const editable = useMemo(() => action === "edit" || id === "new", [action, id]);

  useEffect(() => {
    getService({ id });
  }, [dispatch, id]);

  const {
    serviceData,
    serviceLoading,
    serviceAction,
    serviceLimit,
    servicePage,
    serviceTotal,
    serviceMessage,
    Detailsuccess,
    serviceError,
  } = useMemoizedSelector((state) => ({
    serviceData: state.Services.service,
    serviceLoading: state.Services.loading,
    serviceAction: state.Services.action,
    serviceLimit: state.Services.limit,
    servicePage: state.Services.page,
    serviceTotal: state.Services.total,
    serviceMessage: state.Services.loading,
    serviceSuccess: state.Services.success,
    serviceError: state.Services.error,
  }));

  const refForm = useRef();
  const formValidation = {
    initialValues: {
      name: serviceData?.name || "",
      code: serviceData?.code || "",
      description: serviceData.description || "",
      features: serviceData?.features || {},
      plans: serviceData?.plans || [],
      logo: serviceData.logo || null,
    },
    onSubmit: async (values, actions) => {
      if (id !== "new") {
        dispatch(
          updateService({ ...serviceData, ...values }, (data) => {
            navigate(`/services/${data.id}`);
          }),
        );
      } else {
        dispatch(
          addService(values, (data) => {
            navigate(`/services/${data.id}`);
          }),
        );
      }
    },
  };

  //state data
  const [openModalSubscription, setOpenModalSubscription] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectPlan, setSelectPlan] = useState({});

  const handleAddSubscription = (dataPlan) => {
    setSelectPlan(dataPlan);
    setOpenModalSubscription(true);
  };

  const handleClickDeleteService = () => {
    dispatch(
      deleteService({ id }, () => {
        setDeleteModal(false);
        navigate("/services");
      }),
    );
  };

  useEffect(() => {
    if (id !== "new") {
      dispatch(getService(id));
    } else {
      dispatch(resetService());
    }
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <StatusModal
          show={deleteModal}
          onClick={handleClickDeleteService}
          onCloseClick={() => setDeleteModal(false)}
          loading={serviceLoading}
        />
        <ModalSubscription
          open={openModalSubscription}
          toggle={() => setOpenModalSubscription(false)}
          selectService={serviceData}
          selectPlan={selectPlan}
        />
        <BreadCrumbCustom text="Back to service list" navigateTo="/services">
          <div className="hstack gap-2">
            {editable && (
              <ButtonTheme
                type="button"
                loadShowText={true}
                className="btn btn-primary w-sm"
                onClick={async () => {
                  refForm.current.handleSubmit();
                }}
              >
                {t(id === "new" ? "Create" : "Save")}
              </ButtonTheme>
            )}
            {id !== "new" && (
              <ButtonTheme
                type="button"
                loadShowText={true}
                className="btn btn-light w-sm"
                onClick={() => navigate(action === "edit" ? `/services/${id}` : `/services/${id}/edit`)}
              >
                {t(action === "edit" ? "Close" : "Edit")}
              </ButtonTheme>
            )}
            {!editable && (
              <ButtonTheme
                type="button"
                className="btn btn-danger w-sm"
                onClick={() => {
                  setDeleteModal(true);
                }}
              >
                {t("Delete")}
              </ButtonTheme>
            )}
          </div>
        </BreadCrumbCustom>

        <Formik
          enableReinitialize={true}
          initialValues={formValidation.initialValues}
          onSubmit={formValidation.onSubmit}
          innerRef={refForm}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Card>
                <CardBody>
                  <div className="hstack gap-5 mb-3">
                    <div className="flex-1">
                      <InputTheme name="name" label={"Name"} disabled={!editable} />
                      <InputTheme name="code" label={"Code"} disabled={!editable} />
                    </div>
                    <div>
                      <UploadTheme name="logo" label={"Logo"} disabled={!editable} />
                    </div>
                  </div>
                  <InputTheme name="description" type="textarea" label={"Description"} disabled={!editable} />
                  <CollapsePermissionCard
                    values={values}
                    onChangeData={(data) => {
                      setFieldValue("features", data);
                    }}
                    editable={editable}
                  />
                </CardBody>
              </Card>
              <FieldArray name="plans">
                {({ push, remove }) => {
                  const isEmptyOrUndefined = (value) => {
                    return value === undefined || value === "";
                  };

                  const validatePlan = (plan) => {
                    const requiredFields = ["name", "code", "price", "sale_price"];
                    return requiredFields.every((field) => !isEmptyOrUndefined(plan[field]));
                  };
                  const handleAddPlan = () => {
                    const isAnyPlanIncomplete = values.plans.some((plan) => !validatePlan(plan));

                    if (isAnyPlanIncomplete) {
                      toast.error("Please fill in all fields before adding new plan!");
                      return;
                    }
                    push({});
                    setSelectedTab(values.plans.length);
                  };
                  const handleRemovePlan = (index) => {
                    remove(index);
                    setSelectedTab(index > 0 ? index - 1 : 0);
                  };
                  return (
                    <Card>
                      <CardBody>
                        <CardTitle className="hstack justify-content-between">
                          {t("Plans")}{" "}
                          {editable && (
                            <ButtonTheme type="button" onClick={handleAddPlan} className="btn btn-light border">
                              <i className="ri-add-line me-1"></i>
                              {t("Add new plan")}
                            </ButtonTheme>
                          )}
                        </CardTitle>
                        <Nav className="mb-3" tabs>
                          {values.plans.map((item, index) => (
                            <NavItem key={index}>
                              <NavLink
                                className="cursor-pointer"
                                active={selectedTab === index}
                                onClick={() => {
                                  setSelectedTab(index);
                                }}
                              >
                                {`${t("Plan")} ${index + 1}`}
                              </NavLink>
                            </NavItem>
                          ))}
                        </Nav>
                        <TabContent activeTab={selectedTab}>
                          {values.plans.map((item, index) => (
                            <TabPane tabId={index} key={index} className="box-setting p-1 mb-3">
                              <Row>
                                <Col lg={6}>
                                  <InputTheme name={`plans.${index}.name`} label={"Name"} disabled={!editable} />
                                </Col>
                                <Col lg={6}>
                                  <InputTheme name={`plans.${index}.code`} label={"Code"} disabled={!editable} />
                                </Col>
                                <Col lg={6}>
                                  <InputTheme
                                    name={`plans.${index}.price`}
                                    label={"Price"}
                                    format="price"
                                    disabled={!editable}
                                  />
                                </Col>
                                <Col lg={6}>
                                  <InputTheme
                                    name={`plans.${index}.sale_price`}
                                    label={"Sale Price"}
                                    format="price"
                                    disabled={!editable}
                                  />
                                </Col>
                                <Col lg={12}>
                                  <div className="align-items-center d-flex">
                                    <CollapsePermissionCard
                                      values={item}
                                      onChangeData={(data) => {
                                        setFieldValue(`plans.${index}.features`, data);
                                      }}
                                      permissions={values.features}
                                      editable={editable}
                                    />
                                  </div>
                                </Col>
                                <Col lg={12} className="float-end">
                                  {editable ? (
                                    <ButtonTheme
                                      type="button"
                                      onClick={() => handleRemovePlan(index)}
                                      className="btn btn-danger"
                                    >
                                      {t("Remove plan")}
                                    </ButtonTheme>
                                  ) : (
                                    <ButtonTheme
                                      type="button"
                                      onClick={() => handleAddSubscription(item)}
                                      className="btn btn-success border"
                                    >
                                      <i className="bx bxs-cart-add me-1"></i>
                                      {t("Add Subscription")}
                                    </ButtonTheme>
                                  )}
                                </Col>
                              </Row>
                            </TabPane>
                          ))}
                        </TabContent>
                      </CardBody>
                    </Card>
                  );
                }}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

const CollapsePermissionCard = ({ values, permissions = null, onChangeData, editable = true }) => {
  const { t } = useTranslation();

  const filterPermissions = (data, filter) => {
    // Clone permissions object to avoid modifying original
    const result = JSON.parse(JSON.stringify(data));

    // Helper function to check if a module should be included
    const shouldIncludeModule = (moduleName, moduleFilter) => {
      if (!moduleFilter) return false;
      return Object.keys(moduleFilter).some((permission) => moduleFilter[permission]);
    };

    // Iterate through all modules in permissions
    Object.keys(result).forEach((moduleName) => {
      // Check if module exists in filter
      if (!filter[moduleName] || !shouldIncludeModule(moduleName, filter[moduleName])) {
        delete result[moduleName];
        return;
      }

      // Keep only filtered permissions within the module
      Object.keys(result[moduleName]).forEach((permission) => {
        if (!filter[moduleName][permission]) {
          delete result[moduleName][permission];
        } else {
          // Set active to true for filtered permissions
          result[moduleName][permission].active = true;
        }
      });
    });

    return result;
  };

  const filteredPermissions = permissions
    ? filterPermissions(supported_permissions, permissions)
    : supported_permissions;

  const fullPermissions = Object.keys(filteredPermissions).map((key) => ({
    key: key,
    label: key,
    children: Object.keys(filteredPermissions[key]).map((item) => ({
      key: item,
      label: filteredPermissions[key][item].description,
      defaultActive: filteredPermissions[key][item].active,
    })),
  }));

  const [collapse, setCollapse] = useState(true);
  return (
    <div className="mb-3">
      <Label
        className="mb-0 d-flex gap-2 flex-wrap align-items-center cursor-pointer"
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        {t("Features")}
        <i
          className="ri-arrow-right-s-line fs-19"
          style={{
            transform: `${collapse ? "rotate(90deg)" : "rotate(0deg)"}`,
            transition: "all 0.3s ease",
          }}
        ></i>
      </Label>
      <Collapse isOpen={collapse}>
        {fullPermissions.length === 0 ? (
          <div>{t("No permission available")}</div>
        ) : (
          fullPermissions.map((permission, index) => (
            <CollapsePermission
              key={index}
              list={permission}
              onChangeData={(data) => {
                const newData = deepCopy(values?.features ?? {});
                const newFeatures = { ...newData, ...data };
                onChangeData(newFeatures);
              }}
              dataSelect={values?.features ?? {}}
              lastKey={fullPermissions.length - 1 === index ? true : false}
              disabled={!editable}
            />
          ))
        )}
      </Collapse>
    </div>
  );
};

const CollapsePermission = ({ list, onChangeData = () => {}, lastKey, dataSelect = {}, disabled = false }) => {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectAllChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { checked } = e.target;
    const keys = checked ? list.children.map((k) => k.key) : [];
    setSelectedRowKeys(keys);
    handleChange(keys);
  };

  const handleChange = (data) => {
    const obj = list.children.reduce((acc, current, index, array) => {
      acc[current.key] = data?.find((item) => item === current.key) ? true : false;
      return acc;
    }, {});

    onChangeData({
      [list.key]: obj,
    });
  };

  useEffect(() => {
    if (Object.keys(dataSelect).length > 0 && dataSelect[list.key]) {
      const arrayKey = Object.keys(dataSelect[list.key]);
      const filterArrayKey = arrayKey.filter((item) => dataSelect[list.key][item]);
      setSelectedRowKeys(filterArrayKey);
    }
  }, [dataSelect]);

  const formatString = (input) => {
    return input
      .split("_") // Split by underscore
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join with spaces
  };

  return (
    <div className={`${lastKey ? "" : "border-bottom"} `}>
      <div className="d-flex gap-2 flex-wrap align-items-center px-2 py-3">
        <Checkbox
          indeterminate={selectedRowKeys?.length > 0 && selectedRowKeys?.length < list.children?.length}
          checked={selectedRowKeys?.length === list.children?.length && selectedRowKeys?.length > 0}
          onChange={onSelectAllChange}
          disabled={disabled}
        />

        <div
          className="d-flex gap-2 flex-wrap align-items-center cursor-pointer"
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          <span>{t(formatString(list.label))}</span>

          <i
            className="ri-arrow-right-s-line fs-19"
            style={{
              transform: `${collapse ? "rotate(90deg)" : "rotate(0deg)"}`,
              transition: "all 0.3s ease",
            }}
          ></i>
        </div>
      </div>
      <Collapse isOpen={collapse}>
        <Card className="mb-0">
          <CardBody>
            <Row>
              {list.children.map((permission, index) => (
                <Col lg={4} key={index}>
                  <Checkbox
                    disabled={disabled}
                    checked={selectedRowKeys.includes(permission?.key)}
                    onChange={(e) => {
                      const check = e.target.checked;
                      const keyRow = permission?.key;
                      if (check) {
                        const newData = [...selectedRowKeys, keyRow];
                        setSelectedRowKeys(newData);
                        handleChange(newData);
                      } else {
                        const newData = selectedRowKeys.filter((item) => item !== keyRow);
                        setSelectedRowKeys(newData);
                        handleChange(newData);
                      }
                    }}
                    className="mb-3"
                  >
                    {t(permission?.label)}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default withRouter(ServiceDetails);
