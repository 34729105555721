import React, { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { get } from "lodash";

//i18n
import i18n, { resources } from "../../i18n";
import languages from "../../common/languages";
import { useSearchParams } from "react-router-dom";

const LanguageDropdown = () => {
  const [searchParams] = useSearchParams();
  const language = searchParams.get("lang");

  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState("");

  useEffect(() => {
    const localLanguage = localStorage.getItem("I18N_LANGUAGE");
    const browserLang = window.navigator.language.split("-")[0];
    let language = "vi";
    if (resources?.[localLanguage]) {
      language = localLanguage;
    } else if (resources?.[browserLang]) {
      language = browserLang;
    }
    changeLanguageAction(language);
  }, []);

  const changeLanguageAction = (lang) => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);
  };

  const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
  const toggleLanguageDropdown = () => {
    setIsLanguageDropdown(!isLanguageDropdown);
  };
  useEffect(() => {
    if (resources?.[language]) {
      changeLanguageAction(language);
    }
  }, [language]);
  return (
    <React.Fragment>
      <Dropdown
        id="language-topbar"
        isOpen={isLanguageDropdown}
        toggle={toggleLanguageDropdown}
        className="ms-1 topbar-head-dropdown header-item"
      >
        <DropdownToggle className="btn btn-icon rounded-circle" tag="button">
          <img src={get(languages, `${selectedLang}.flag`)} alt="Header Language" height="20" className="rounded" />
        </DropdownToggle>
        <DropdownMenu className="notify-item language py-2">
          {Object.keys(languages).map((key) => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${selectedLang === key ? "active" : "none"}`}
            >
              <img src={get(languages, `${key}.flag`)} alt="Skote" className="me-2 rounded" height="18" />
              <span className="align-middle">{get(languages, `${key}.label`)}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LanguageDropdown;
