import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { withTranslation } from "react-i18next";

const BreadCrumbCustom = ({ text, navigateTo = "#", t, children }) => {
  return (
    <React.Fragment>
      <Row className="position-sticky" style={{ top: "calc(70px + 1.5rem)", zIndex: 1002, height: "60px" }}>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between border-top">
            <Link to={navigateTo} className="hstack">
              <i className="bx bx-arrow-back cursor-pointer align-bottom fs-23 px-2" />
              <span className="align-center">{t(text)}</span>
            </Link>
            <div className="d-flex">{children}</div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(BreadCrumbCustom);
