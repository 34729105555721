import { RESET_PASSWORD, RESET_PASSWORD_FAIL, RESET_PASSWORD_SUCCESS, RESET_RESET_PASSWORD_FLAG } from "./actionTypes";

const initialState = {
  resetPassMsg: "",
  error: "",
  loading: false,
};

const resetPassword = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        loading: false,
        resetPassMsg: action.payload,
      };
      break;
    case RESET_PASSWORD_FAIL:
      state = { ...state, loading: false, error: action.payload };
      break;

    case RESET_RESET_PASSWORD_FLAG:
      state = {
        ...state,
        resetPassMsg: "",
        error: "",
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default resetPassword;
