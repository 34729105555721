import axios from "axios";
import { api } from "../config";
import { getInfoAPI, refreshTokenAPI } from "./AuthAPI";

const defaultConfig = {
  baseURL: api.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

// Create Axios instance with default configuration
const defaultAxios = axios.create(defaultConfig);

// Axios instance for requests using access token
const tokenAxios = axios.create(defaultConfig);

const getToken = () => {
  try {
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    return auth_user?.Token?.IdToken || null;
  } catch (e) {
    return null;
  }
};

const getAccessToken = () => {
  try {
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    return auth_user?.Token?.AccessToken || null;
  } catch (e) {
    return null;
  }
};

const getRefreshToken = () => {
  try {
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    return auth_user?.Token?.RefreshToken || null;
  } catch (e) {
    return null;
  }
};

const getTokenExpiresIn = () => {
  try {
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    return auth_user?.Token?.ExpiresIn || null;
  } catch (e) {
    return null;
  }
};

const getLoggedInUser = () => {
  try {
    const user = localStorage.getItem("auth_user");
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.error("Invalid auth user", String(error));
    return null;
  }
};

class APIClient {
  constructor(axiosInstance) {
    this.axiosInstance = axiosInstance;
  }
  get = (url, params) => {
    const queryString = params
      ? Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")
      : "";
    return this.axiosInstance.get(`${url}${queryString ? `?${queryString}` : ""}`);
  };

  create = (url, data) => {
    return this.axiosInstance.post(url, data);
  };

  update = (url, data) => {
    return this.axiosInstance.patch(url, data);
  };

  put = (url, data, config) => {
    return this.axiosInstance.put(url, data, { ...config });
  };

  delete = (url, config) => {
    return this.axiosInstance.delete(url, config);
  };
}

export const defaultApiCli = new APIClient(defaultAxios);
export const apiCli = new APIClient(tokenAxios);

tokenAxios.interceptors.request.use(
  (config) => {
    const token = getToken();
    const accessToken = getAccessToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (accessToken) {
      config.params = { ...config.params, access_token: accessToken };
    }

    if (config.url === "auth/refresh") {
      delete config.params;
      delete config.headers["Authorization"];
    }
    return config;
  },
  (error) => Promise.reject(error),
);

const failedQueue = [];
function callBackFailedRequests() {
  failedQueue.forEach((prom) => prom());
  failedQueue.length = 0;
}
function addFailedQueue(callback) {
  failedQueue.push(callback);
}

let isRefreshing = false;
let lastRefreshTime = 0;

// Helper functions
const shouldRefreshToken = (status, errorMessage, request) => {
  return status === 401 && !request._retry && getToken() && errorMessage === "Unauthorized";
};

const handleTokenRefresh = async (originalRequest) => {
  const retryOriginalRequest = new Promise((resolve) => {
    addFailedQueue(() => resolve(tokenAxios.request(originalRequest)));
  });

  if (shouldInitiateRefresh()) {
    try {
      await refreshToken();
      updateOriginalRequest(originalRequest);
      callBackFailedRequests();
    } catch (error) {
      handleRefreshError();
    } finally {
      isRefreshing = false;
    }
  }

  return retryOriginalRequest;
};

const shouldInitiateRefresh = () => {
  if (isRefreshing) return false;

  const currentTime = Date.now();
  const expiresIn = getTokenExpiresIn();
  return !expiresIn || currentTime - lastRefreshTime >= expiresIn * 1000;
};

const refreshToken = async () => {
  isRefreshing = true;
  lastRefreshTime = Date.now();

  const refreshToken = getRefreshToken();
  const response = await refreshTokenAPI({ refresh_token: refreshToken });

  updateStoredToken(response);
};

const updateStoredToken = (newTokenData) => {
  const auth_user = JSON.parse(localStorage.getItem("auth_user"));
  const updatedToken = { ...auth_user.Token, ...newTokenData };

  localStorage.setItem(
    "auth_user",
    JSON.stringify({
      ...auth_user,
      Token: updatedToken,
    }),
  );
};

const updateOriginalRequest = (request) => {
  request.headers.Authorization = `Bearer ${getToken()}`;
};

const handleRefreshError = () => {
  // Implement your logout logic here
  // window.location.href = "/logout";
  console.error("Token refresh failed");
};

const getErrorMessage = (status, message) => {
  const errorMessages = {
    500: message || "Internal Server Error",
    401: message || "Invalid credentials",
    404: message || "Sorry! The data you are looking for could not be found",
    413: message || "File size too large",
    default: message || "Something went wrong",
  };

  return errorMessages[status] || errorMessages.default;
};

// Response interceptor
tokenAxios.interceptors.response.use(
  (response) => response.data || response,
  async (error) => {
    // Extract important data from error
    const { response, config: originalRequest } = error;
    const status = response?.status;
    const errorMessage = response?.data?.message;

    // Handle token refresh
    if (shouldRefreshToken(status, errorMessage, originalRequest)) {
      return handleTokenRefresh(originalRequest);
    }

    // Handle other errors
    const finalError = getErrorMessage(status, errorMessage);
    return Promise.reject(finalError);
  },
);

defaultAxios.interceptors.response.use(
  (response) => response.data || response,
  async (error) => {
    let errorMessage = error?.response?.data?.message;
    return Promise.reject(errorMessage);
  },
);

const setAuthorization = (token) => {
  tokenAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

class Resource {
  constructor(resourceName, isPublic = false) {
    this.resourceName = resourceName;
    this.api_client = new APIClient(isPublic ? defaultAxios : tokenAxios);
  }

  list = (params) => this.api_client.get(this.resourceName, params);

  customList = (customPath, params) => this.api_client.get(`${this.resourceName}/${customPath}`, params);

  get = ({ id: resourceId }) => this.api_client.get(`${this.resourceName}/${resourceId}`);

  filter = (params) => this.api_client.get(this.resourceName, params);

  create = (payload) => this.api_client.create(this.resourceName, payload);

  customCreate = (customPath, payload) => this.api_client.create(`${this.resourceName}/${customPath}`, payload);

  createCustomPath = (customPath, payload) => this.api_client.create(`${this.resourceName}/${customPath}`, payload);

  update = (payload) => this.api_client.update(`${this.resourceName}/${payload.id}`, payload);

  put = (payload) => this.api_client.put(`${this.resourceName}/${payload.id}`, payload);

  customPut = (customPath, payload) => this.api_client.put(`${this.resourceName}/${customPath}`, payload);

  delete = ({ id: resourceId }) => this.api_client.delete(`${this.resourceName}/${resourceId}`);

  customDelete = (customPath) => this.api_client.delete(`${this.resourceName}/${customPath}`);
}

export { APIClient, Resource, setAuthorization, getLoggedInUser };
