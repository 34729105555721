import { useFormikContext } from "formik";
import { useEffect } from "react";

const ScrollToError = ({ offset = 0 }) => {
  const { errors, isSubmitting, isValidating } = useFormikContext();
  useEffect(() => {
    if (isSubmitting && !isValidating && Object.keys(errors).length > 0) {
      const firstErrorField = Object.keys(errors)[0];
      const errorElement = document.getElementsByName(firstErrorField)[0];

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isSubmitting, isValidating, errors, offset]);

  return null;
};

export default ScrollToError;
