import { Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useField } from "formik";
import { Label } from "reactstrap";
import { withTranslation } from "react-i18next";
import { uploadImageApi } from "../../helpers/resource_helper";

function UploadTheme(props) {
  const { name, label, t, disabled } = props;
  const [field, meta, helpers] = useField(name);
  const showError = meta.error && meta.touched;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (field.value) {
      setFileList([
        {
          uid: "-1",
          status: "done",
          url: field.value,
        },
      ]);
    } else {
      setFileList([]);
    }
  }, [field.value]);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList?.[0]?.response?.url) {
      helpers.setValue(newFileList?.[0]?.response?.url);
    }
    if (newFileList.length === 0) {
      helpers.setValue(null);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const customRequest = async ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials,
  }) => {
    const payload = {
      name: file.name,
      image: await getBase64(file),
    };
    try {
      const response = await uploadImageApi.create(payload);
      onSuccess(response, file);
    } catch (error) {
      onError();
    }
    return {
      abort() {
        console.log("upload progress is aborted.");
      },
    };
  };

  return (
    <>
      {label && <Label>{t(label)}</Label>}
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={customRequest}
        defaultFileList={field.value ? fileList : null}
        disabled={disabled}
      >
        {fileList.length === 1 ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel} zIndex={1003}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
}
export default withTranslation()(UploadTheme);
