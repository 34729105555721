// Upload image
export const LIST_SERVICE = "LIST_SERVICE";
export const LIST_SERVICE_SUCCESS = "LIST_SERVICE_SUCCESS";
export const LIST_SERVICE_ERROR = "LIST_SERVICE_ERROR";

export const GET_SERVICE = "GET_SERVICE";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_ERROR = "GET_SERVICE_ERROR";

export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_ERROR = "UPDATE_SERVICE_ERROR";

export const DELETE_SERVICE = "DELETE_SERVICE";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_ERROR = "DELETE_SERVICE_ERROR";

export const ADD_SERVICE = "ADD_SERVICE";
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_ERROR = "ADD_SERVICE_ERROR";

export const RESET_SERVICE = "RESET_SERVICE";

