import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Alert } from "reactstrap";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";

import { changePass } from "../../../../store/auth/changepwd/actions";
import InputTheme from "../../../../Components/Common/InputTheme";
import ScrollToError from "../../../../Components/Common/ScrollToError";

const ChangePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userSession = JSON.parse(localStorage.getItem("auth_user"));
  const formRef = useRef();

  const validation = {
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t("Please Enter Your Password")),
      new_password: Yup.string()
        .min(8, t("Password must be at least 8 characters"))
        .matches(RegExp("(.*[a-z].*)"), t("At least lowercase letter"))
        .matches(RegExp("(.*[A-Z].*)"), t("At least uppercase letter"))
        .matches(RegExp("(.*[0-9].*)"), t("At least one number"))
        .matches(/[/\W|_/g]/, t("At least one symbol"))
        .required(t("Please Enter Your New Password")),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], t("Passwords must match"))
        .required(t("Confirm password is required")),
    }),
    onSubmit: (values) => {
      const payload = { username: userSession.Username, password: values.password, new_password: values.new_password };
      dispatch(
        changePass(payload, () => {
          formRef.current.handleReset();
        }),
      );
    },
  };

  const { error, loading, changePassMsg } = useMemoizedSelector((state) => ({
    changePassMsg: state.ChangePassword.changePassMsg,
    error: state.ChangePassword.error,
    loading: state.ChangePassword.loading,
  }));

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(resetChangePassFlag());
  //   }, 3000);
  // }, [dispatch, error, loading]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={validation.initialValues}
      validationSchema={validation.validationSchema}
      onSubmit={validation.onSubmit}
      innerRef={formRef}
    >
      {({ values, setFieldValue, errors, touched }) => {
        return (
          <Form>
            <ScrollToError />
            {error && error ? (
              <Alert color="danger">
                <div>{t(error)}</div>
              </Alert>
            ) : null}
            {changePassMsg && changePassMsg ? (
              <Alert color="success">
                <div>{t(changePassMsg)}</div>
              </Alert>
            ) : null}
            <div className="row g-2">
              <div className="col-lg-4">
                <InputTheme
                  type="password"
                  name="password"
                  label={`${t("Old Password")}*`}
                  disabled={loading}
                  placeholder="Enter current password"
                />
              </div>
              <div className="col-lg-4">
                <InputTheme
                  type="password"
                  name="new_password"
                  label={`${t("New Password")}*`}
                  disabled={loading}
                  placeholder="Enter new password"
                />
              </div>
              <div className="col-lg-4">
                <InputTheme
                  type="password"
                  name="confirm_password"
                  label={`${t("Confirm Password")}*`}
                  disabled={loading}
                  placeholder="Confirm password"
                />
              </div>
              <div className="col-lg-12">
                <div className="mb-3">
                  <Link to="/forgot-password" className="text-decoration-underline">
                    {t("Forgot password?")}
                  </Link>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="text-end">
                  <ButtonTheme type="submit" className="btn btn-success" loading={loading} disabled={loading}>
                    {t("Change Password")}
                  </ButtonTheme>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePassword;
