import { call, put, takeEvery } from "redux-saga/effects";

import { changePasswordAPI, getInfoAPI } from "../../../helpers/AuthAPI";
import { setAuthorization } from "../../../helpers/api_helper";

import { CHANGE_PASSWORD } from "./actionTypes";

import { changePassFail, changePassSuccess } from "./actions";

function* changePassword({ payload: { data, history } }) {
  try {
    const response = yield call(changePasswordAPI, data);
    if (response?.message) {
      yield put(changePassSuccess(response.message));
    } else {
      const { AccessToken, RefreshToken, IdToken } = response;
      setAuthorization(IdToken);
      const userInfoResponse = yield call(getInfoAPI, AccessToken);
      if (userInfoResponse) {
        let userAttributes = {};
        userInfoResponse.UserAttributes.forEach((item) => (userAttributes[item.Name] = item.Value));
        const auth_user = {
          ...userInfoResponse,
          UserAttributes: userAttributes,
          Token: response,
        };
        localStorage.setItem("auth_user", JSON.stringify(auth_user));
        history("/dashboard");
      } else {
        yield put(changePassFail(userInfoResponse));
      }
    }
  } catch (error) {
    yield put(changePassFail(error));
  }
}
function* ChangePassSaga() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

export default ChangePassSaga;
