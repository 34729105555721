import React from "react";
import { Navigate } from "react-router-dom";

//AuthenticationInner pages
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";

import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import BasicSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg";
import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import VerifyConfirmationCodePage from "../pages/Authentication/VerifyConfirmationCode";
import ResetPasswordPage from "../pages/Authentication/ResetPassword";
import ChangePasswordPage from "../pages/Authentication/ChangePassword";

// User Profile
import Settings from "../pages/Profile/Settings";
import languages from "../common/languages";
import DashboardAnalytics from "../pages/DashboardAnalytics";
import FormCompany from "../pages/Company/FormCompany";
import ListUser from "../pages/User/ListUser";
import ListCompany from "../pages/Company/ListCompany";
import Services from "../pages/Services";
import Subscription from "../pages/Subscription";
import Message from "../pages/Message";
import ServiceDetails from "../pages/Services/ServiceDetails";

const ADMIN = "Admin";
const USER = "User";

const authProtectedRoutes = [
  {
    path: "/",
    exact: true,
    component: <DashboardAnalytics />,
  },
  {
    path: "/dashboard",
    component: <DashboardAnalytics />,
  },
  {
    path: "/add-company",
    component: <FormCompany />,
  },
  {
    path: "/list-company",
    component: <ListCompany />,
  },
  {
    path: "/company/:id",
    component: <FormCompany />,
  },
  {
    path: "/list-user",
    component: <ListUser />,
  },
  {
    path: "/services",
    component: <Services />,
  },
  {
    path: "/services/:id",
    component: <ServiceDetails />,
  },
  {
    path: "/services/:id/:action",
    component: <ServiceDetails />,
  },
  {
    path: "/list-subscription",
    component: <Subscription />,
  },
  {
    path: "/list-message",
    component: <Message />,
  },
  //User Profile
  // { path: "/profile", component: <UserProfile /> },
  { path: "/profile-settings", component: <Settings /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/verify-confirmation-code", component: <VerifyConfirmationCodePage /> },
  { path: "/reset-password", component: <ResetPasswordPage /> },
  { path: "/change-password", component: <ChangePasswordPage /> },

  //AuthenticationInner pages
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },

  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },
];

export { authProtectedRoutes, publicRoutes };
