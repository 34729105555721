import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { useNavigate } from "react-router-dom";

const ProfileDropdown = ({ t }) => {
  const userSession = JSON.parse(localStorage.getItem("auth_user"));
  const navigate = useNavigate();
  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
        <DropdownToggle tag="button" type="button" className="btn">
          <div className="d-flex align-items-center">
            {userSession?.UserAttributes?.image != null ? (
              <img
                src={userSession.UserAttributes.image}
                alt={avatar1}
                className="rounded-circle header-profile-user"
              />
            ) : (
              <div className="header-profile-user">
                <div className="rounded-circle text-warning bg-light avatar-title text-uppercase border">
                  {userSession?.Username?.charAt(0)}
                </div>
              </div>
            )}
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userSession?.Username}</span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {userSession?.UserAttributes?.["custom:role"]}
              </span>
            </span>
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">{`${t("Welcome")} ${userSession?.Username}!`}</h6>
          <DropdownItem onClick={() => navigate("/profile-settings")}>
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">{t("Profile")}</span>
          </DropdownItem>
          {/* <DropdownItem onClick={() => navigate("/profile-settings")}>
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Settings</span>
          </DropdownItem> */}
          <DropdownItem onClick={() => navigate("/logout")}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              {t("Log out")}
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(ProfileDropdown);
