import { RESET_PASSWORD, RESET_PASSWORD_FAIL, RESET_PASSWORD_SUCCESS, RESET_RESET_PASSWORD_FLAG } from "./actionTypes";

export const resetPass = (data) => {
  return {
    type: RESET_PASSWORD,
    payload: data,
  };
};

export const resetPassSuccess = (data) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const resetPassFail = (error) => {
  return {
    type: RESET_PASSWORD_FAIL,
    payload: error,
  };
};

export const resetResetPassFlag = () => {
  return {
    type: RESET_RESET_PASSWORD_FLAG,
  };
};
