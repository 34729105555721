import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationVN from "./locales/vn.json";
import translationENG from "./locales/en.json";

// the translations
export const resources = {
  en: {
    translation: translationENG,
  },
  vi: {
    translation: translationVN,
  },
};

let language = "vi";
if (typeof window !== "undefined" && window.localStorage) {
  const local_language = localStorage.getItem("I18N_LANGUAGE");
  if (local_language) {
    language = resources[local_language] ? local_language : language;
  } else {
    const currentLanguage = window.navigator.language.split("-")[0];
    language = resources[currentLanguage] ? currentLanguage : language;
    localStorage.setItem("I18N_LANGUAGE", language);
  }
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    fallbackLng: "vi", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
