import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

import withRouter from "../../Components/Common/withRouter";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";

import { logoutUser } from "../../store/actions";

const Logout = (props) => {
  const dispatch = useDispatch();

  const { isUserLogout } = useMemoizedSelector((state) => ({
    isUserLogout: state.Login.isUserLogout,
  }));

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  if (isUserLogout) {
    return <Navigate to="/login" />;
  }

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
