import { Fragment } from "react";
import { TagsInput } from "react-tag-input-component";
import { isEmpty } from "lodash";

const TagsInputCustom = ({ placeHolder, value, name, onChangeTagInputs, disabled = false, classNames }) => {
  const beforeAddValidate = (tag, tags) => {
    let newTag = tag.trim();
    return isEmpty(newTag) ? false : !tags.includes(newTag);
  };
  return (
    <Fragment>
      <TagsInput
        classNames="w-100"
        value={value}
        onChange={onChangeTagInputs}
        separators={["Enter", " "]}
        name={name}
        placeHolder={placeHolder}
        beforeAddValidate={beforeAddValidate}
        disabled={disabled}
      />
    </Fragment>
  );
};
export default TagsInputCustom;
