import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  RESET_CHANGE_PASSWORD_FLAG,
} from "./actionTypes";

const initialState = {
  changePassMsg: "",
  error: "",
  loading: false,
};

const changePassword = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        loading: false,
        changePassMsg: action.payload,
      };
      break;
    case CHANGE_PASSWORD_FAIL:
      state = { ...state, loading: false, error: action.payload };
      break;

    case RESET_CHANGE_PASSWORD_FLAG:
      state = {
        ...state,
        changePassMsg: "",
        error: "",
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default changePassword;
