import { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner } from "reactstrap";
import withRouter from "./withRouter";
import PaginationCustom from "./PaginationCustom";
import FilterComponent from "./FilterComponent";
import { Checkbox, Table } from "antd";
import { formatDateTime } from "../../helpers/format_helper";
import { useTranslation } from "react-i18next";
import ModalContainer from "./ModalContainer";
import ButtonTheme from "./ButtonTheme";
import { Input, Tree, Button } from "antd";

const { Search } = Input;

const TableContainerCustom = ({
  showSearch = true,
  showPagination = true,
  filterType = "",
  numberOfFilters = 3,
  searchPlaceHolder = "Search...",
  listFilter = [],
  onCallData = () => {},
  onRowClick = () => {},
  contents = [],
  headers = [],
  limitPage = 10,
  currentPage,
  totalItems,
  loading,
  scrollHeight,
  tableView = false,
  keyRow,
  defaultPayload = { limit: 20, sort_created_at: "desc" },
  renderExpandedComponent = null,
  defaultDateFilter = true,
  defaultSaveFilter = [],
  isCheckbox = false,
  isSettings = false,
  isRefresh = true,
  listAction = [],
  listHeader = [],
  children,
}) => {
  const loadLocal = JSON.parse(localStorage.getItem("table"));
  const { t } = useTranslation();
  const [firstRun, setFirstRun] = useState(true);
  const [payload, setPayload] = useState(defaultPayload);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [isModal, setIsModal] = useState(false);
  const [itemClickAction, setItemClickAction] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalSettings, setIsModalSettings] = useState(false);
  const onSelectAllChange = (e) => {
    const { checked } = e.target;
    const keys = checked ? contents.map((record) => (record.id ? record.id : record.code)) : [];
    setSelectedRowKeys(keys);
  };

  const handleDeleteItem = () => {
    console.log("selectedRowKeys", selectedRowKeys);
    console.log("contents", contents);
    const newContents = contents.filter((item) => !selectedRowKeys.includes(item.id ? item.id : item.code));
    console.log("newContents", newContents);
  };
  const [loadingButton, setLoadingButton] = useState(false);
  const [headerTable, setHeaderTable] = useState(headers);
  const KEY_SORT = "sort_";

  function flattenData(data) {
    var flatArray = [];

    function flatten(item) {
      flatArray.push(item);
      if (item.children) {
        item.children.forEach(flatten);
      }
    }

    data.forEach(flatten);

    return flatArray;
  }

  const dataListHeader = flattenData(listHeader);

  const sorter = (textTitle, key, titleAlign) => {
    const SorterComponent = ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(({ column }) => column.dataIndex === key);
      return (
        <div className="align-items-center d-flex fl-sb justify-content-between user-select-none" size={"large"}>
          <div className="d-block text-truncate flex-1" style={{ ...(titleAlign && { textAlign: titleAlign }) }}>
            {t(textTitle)}
          </div>
          {sortedColumn && (
            <div style={{ width: "21px", height: "21px", fontSize: "21px", lineHeight: "21px" }}>
              {sortedColumn.order === "asc" ? (
                <i className="ri-arrow-drop-up-fill"></i>
              ) : sortedColumn?.order ? (
                <i className="ri-arrow-drop-down-fill"></i>
              ) : null}
            </div>
          )}
        </div>
      );
    };

    return SorterComponent;
  };

  const mapColumn = (headerTable || [])?.map((item) => {
    let itemHeader = {};
    if (listHeader.length === 0) {
      itemHeader = item;
    } else {
      itemHeader = dataListHeader.find((i) => i.key === item);
    }

    return {
      ...itemHeader,
      keyName: itemHeader?.title,
      editable: true,
      title: sorter(itemHeader?.title, itemHeader?.dataIndex, itemHeader?.titleAlign),
      sortDirections: ["desc", "asc"],
      ...(!itemHeader?.render && {
        render: (dataCol, data) => {
          return <div className="text-truncate d-block">{t(dataCol)}</div>;
        },
      }),
      ...((itemHeader?.dataIndex === "created_at" ||
        itemHeader?.dataIndex === "updated_at" ||
        itemHeader?.dataIndex === "finished_at" ||
        itemHeader?.dataIndex === "imported_at" ||
        itemHeader?.dataIndex === "returned_at" ||
        itemHeader?.dataIndex === "balanced_at" ||
        itemHeader?.dataIndex === "expire_at" ||
        itemHeader?.dataIndex === "close_time" ||
        itemHeader?.dataIndex === "start_date") && {
        render: (dataCol, data) => {
          return <div className="text-truncate d-block">{dataCol ? formatDateTime(dataCol) : "---"}</div>;
        },
      }),
    };
  });
  let columns = [];
  if (isCheckbox || isSettings) {
    columns = [
      {
        title: (
          <div className="d-flex align-items-center">
            {isSettings && (
              <i
                className="ri-settings-3-line fs-20 mx-2 cursor-pointer"
                onClick={() => {
                  setIsModalSettings(true);
                }}
              ></i>
            )}
            {isCheckbox && (
              <div style={{ width: "40px" }}>
                <Checkbox
                  indeterminate={selectedRowKeys?.length > 0 && selectedRowKeys?.length < contents?.length}
                  checked={selectedRowKeys?.length === contents?.length && selectedRowKeys?.length > 0}
                  onChange={onSelectAllChange}
                />
              </div>
            )}
          </div>
        ),
        dataIndex: "key",
        render: (text, record) => (
          <div className={`${isSettings ? "text-end" : ""} `}>
            {isCheckbox && (
              <Checkbox
                checked={selectedRowKeys.includes(record?.id ? record.id : record.code)}
                onChange={(e) => {
                  const check = e.target.checked;
                  const keyRow = record?.id ? record.id : record.code;
                  if (check) {
                    setSelectedRowKeys([...selectedRowKeys, keyRow]);
                  } else {
                    setSelectedRowKeys(selectedRowKeys.filter((item) => item !== keyRow));
                  }
                }}
              />
            )}
          </div>
        ),
        width: 68,
      },

      ...mapColumn,
    ];
  } else {
    columns = mapColumn;
  }

  useEffect(() => {
    if (filterType) {
      if (firstRun) {
        setFirstRun(false);
        return;
      }
      onCallData(payload);
    } else {
      onCallData(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (loadLocal) {
      const dataLocalHeader = loadLocal[filterType];
      if (dataLocalHeader) {
        setHeaderTable(dataLocalHeader);
      } else {
        setHeaderTable(headers);
      }
    } else {
      setHeaderTable(headers);
    }
  }, []);

  const handleChangePaginationFilterSorter = (p, f, s) => {
    if (s?.order && s?.column) {
      const keySort = KEY_SORT + (s.column?.sortKey ? s.column?.sortKey : s.field);
      setPayload((dataPayload) => {
        const clonePayload = { ...dataPayload };
        const arrKeyPayload = Object.keys(clonePayload);
        for (let index = 0; index < arrKeyPayload.length; index++) {
          const key = arrKeyPayload[index];
          if (key.includes(KEY_SORT)) delete clonePayload[key];
        }
        return { ...clonePayload, [keySort]: s.order };
      });
    } else {
      setPayload((dataPayload) => {
        const clonePayload = { ...dataPayload };
        const arrKeyPayload = Object.keys(clonePayload);
        for (let index = 0; index < arrKeyPayload.length; index++) {
          const key = arrKeyPayload[index];
          if (key.includes(KEY_SORT)) delete clonePayload[key];
        }
        return { ...clonePayload };
      });
    }
  };

  return (
    <Fragment>
      <ModalSettingsHeaderTable
        isModalSettings={isModalSettings}
        toggle={() => {
          setIsModalSettings(false);
        }}
        dataHeader={headerTable}
        listHeader={listHeader}
        dataListHeader={dataListHeader}
        onChangeHeader={(h) => {
          setHeaderTable(h);
          const cloneData = loadLocal ? { ...loadLocal, [filterType]: h } : { [filterType]: h };
          localStorage.setItem("table", JSON.stringify(cloneData));
        }}
        headerDefault={headers}
      />
      <ModalContainer
        title={itemClickAction?.subTitle || ""}
        isOpen={isModal}
        toggle={() => {
          setIsModal(false);
        }}
        actionCustom={
          <>
            <ButtonTheme
              type="button"
              className="btn btn-light w-sm"
              onClick={() => {
                setIsModal(false);
              }}
            >
              {t("Close")}
            </ButtonTheme>
            <ButtonTheme
              type="button"
              loadShowText={true}
              loading={loadingButton}
              className="btn btn-primary w-sm"
              onClick={async () => {
                setLoadingButton(true);
                await itemClickAction.onClick(selectedRowKeys);
                setLoadingButton(false);
                setIsModal(false);
                setSelectedRowKeys([]);
                setTimeout(() => {
                  onCallData(payload);
                }, 1000);
              }}
            >
              {t("Confirm")}
            </ButtonTheme>
          </>
        }
      >
        <span>{`Bạn có muốn xác nhận ${itemClickAction.title} ${selectedRowKeys.length} item đã chọn?`}</span>
      </ModalContainer>
      {filterType && (
        <FilterComponent
          showSearch={showSearch}
          filterType={filterType}
          searchPlaceHolder={searchPlaceHolder}
          handleParamSearch={(dataPayload) => {
            const newPayload = { ...dataPayload };
            const arrKeyPayload = Object.keys(payload);
            for (let index = 0; index < arrKeyPayload.length; index++) {
              const key = arrKeyPayload[index];
              if (key.includes(KEY_SORT)) newPayload[key] = payload[key];
              if (key === "limit") newPayload[key] = payload[key];
            }
            setPayload(newPayload);
          }}
          defaultPayload={payload || {}}
          numberOfFilters={numberOfFilters}
          listFilter={listFilter}
          filterView={tableView}
          defaultSaveFilter={defaultSaveFilter}
          defaultDateFilter={defaultDateFilter}
          onCallData={onCallData}
          isRefresh={isRefresh}
          listLoading={loading}
        />
      )}
      <div className="position-relative">
        {selectedRowKeys.length > 0 && (
          <div className="wrapper-header-table gap-3">
            <div>
              <Checkbox
                indeterminate={selectedRowKeys?.length > 0 && selectedRowKeys?.length < contents?.length}
                checked={selectedRowKeys?.length === contents?.length}
                onChange={onSelectAllChange}
              />
            </div>
            <div className="hstack gap-3">
              <span>
                {t("Selected")} {selectedRowKeys.length} {t("in this page")}
              </span>
              <Button onClick={handleDeleteItem}>Delete</Button>
            </div>
            {listAction.length > 0 && (
              <div>
                {listAction.map((item, index) => {
                  if (item.type === "boxSelect") {
                    return (
                      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" key={index}>
                        <DropdownToggle
                          caret
                          data-toggle="dropdown"
                          tag="span"
                          className="text-primary cursor-pointer mx-3 border p-1"
                        >
                          {t("Choose action")}
                        </DropdownToggle>
                        <DropdownMenu>
                          {item.listAction.length > 0 &&
                            item.listAction.map((action, k) => (
                              <DropdownItem
                                key={k}
                                onClick={() => {
                                  setIsModal(true);
                                  setItemClickAction(action);
                                }}
                              >
                                {t(action.title || "---")}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </Dropdown>
                    );
                  }
                  return (
                    <span
                      className="fw-semibold text-primary fs-13 cursor-pointer mx-3"
                      key={index}
                      onClick={() => {
                        setIsModal(true);
                        setItemClickAction(item);
                      }}
                    >
                      {item.title}
                    </span>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {children}
        <Table
          dataSource={contents}
          columns={columns}
          rowClassName="editable-row"
          pagination={false}
          scroll={{
            y: scrollHeight ? scrollHeight : "100%",
          }}
          rowKey={(row) => (keyRow ? row[keyRow] : row?.id ? `${row.id}` : JSON.stringify(row))}
          sortDirections={["DESC", "ASC"]}
          onChange={handleChangePaginationFilterSorter}
          showSorterTooltip={false}
          loading={{
            indicator: (
              <div>
                <Spinner color="primary" />
              </div>
            ),
            spinning: loading,
          }}
          expandable={
            renderExpandedComponent
              ? {
                  expandedRowRender: renderExpandedComponent,
                }
              : {}
          }
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                onRowClick(event, record, rowIndex);
              }, // click row
              onDoubleClick: (event) => {}, // double click row
              onContextMenu: (event) => {}, // right button click row
              onMouseEnter: (event) => {}, // mouse enter row
              onMouseLeave: (event) => {}, // mouse leave row
            };
          }}
          // rowSelection={showCheckbox ? rowSelection : null}
          // rowSelection={rowKey ? rowSelection : null}
        />
      </div>

      {showPagination && totalItems !== 0 && (
        <Row className="mt-1">
          <PaginationCustom
            currentPage={currentPage}
            totalItems={totalItems}
            limit={limitPage}
            payload={payload}
            onGetApi={(data) => {
              setPayload(data);
            }}
          />
        </Row>
      )}
    </Fragment>
  );
};

// const DropDargHeaderTable = ({ dataHeader = [], dataListHeader = [], onDragDropColumn = () => {} }) => {
//   const { t } = useTranslation();
//   const board = {
//     columns: [
//       {
//         id: 1,
//         title: t("Display column"),
//         cards: [],
//       },
//     ],
//   };
//   const [controlledBoard, setBoard] = useState(board);

//   const setColumnBoard = (columns) => ({ columns });
//   const handleCardMove = async (_card, source, destination) => {
//     const updatedBoard = moveCard(controlledBoard, source, destination);
//     setBoard(updatedBoard);
//     onDragDropColumn(updatedBoard.columns[0].cards.map((item) => item.id));
//   };
//   useEffect(() => {
//     const mapResCard = dataHeader?.map((item, index) => {
//       const findDetail = dataListHeader.find((h) => h.key === item);
//       const newData = {
//         id: findDetail?.key,
//         title: findDetail?.title,
//       };
//       return newData;
//     });
//     const newColumn = [
//       {
//         id: 1,
//         title: t("Display column"),
//         cards: mapResCard,
//       },
//     ];
//     setBoard(setColumnBoard(newColumn));
//   }, [dataHeader, dataListHeader]);

//   return (
//     <>
//       <Board
//         onCardDragEnd={handleCardMove}
//         disableColumnDrag
//         renderColumnHeader={(column) => {
//           return (
//             <Card className="mb-2">
//               <CardBody
//                 style={{
//                   borderRadius: "6px",
//                   boxShadow: "0 var(--2px) var(--8px) rgba(0, 0, 0, .08)",
//                 }}
//               >
//                 <div className="d-flex align-items-center">
//                   <div className="flex-grow-1">
//                     <h6 className="fs-14 text-uppercase fw-semibold mb-0">{column.title}</h6>
//                   </div>
//                 </div>
//               </CardBody>
//             </Card>
//           );
//         }}
//         renderCard={(card, { dragging, removeCard }) => {
//           return (
//             <div style={{ width: "280px" }}>
//               <Card className={"mb-2 ribbon-box ribbon-fill ribbon-sm"}>
//                 <CardBody className="px-1 py-2">
//                   <div className="flex-grow-1 ms-3 d-flex justify-content-between">
//                     <h6 className="fs-14 mb-1">{card.title}</h6>
//                     <span
//                       className="cursor-pointer px-2"
//                       onClick={() => {
//                         onDragDropColumn(
//                           controlledBoard.columns[0].cards.filter((h) => h.id !== card.id).map((item) => item.id),
//                         );
//                       }}
//                     >
//                       <i className="ri-close-line fs-19"></i>
//                     </span>
//                   </div>
//                 </CardBody>
//               </Card>
//             </div>
//           );
//         }}
//         renderColumnAdder={({ addColumn }) => {
//           return <div onClick={() => addColumn({})}>{t("Add column")}</div>;
//         }}
//       >
//         {controlledBoard}
//       </Board>
//     </>
//   );
// };

const ViewFullHeaderTable = ({
  dataHeader = [],
  dataListHeader = [],
  listHeader = [],
  onChangeShowHeader = () => {},
}) => {
  const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  };
  const [expandedKeys, setExpandedKeys] = useState(listHeader.map((item) => item.key));
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState(dataHeader);
  const onChange = (e) => {
    const { value } = e.target;
    // const newExpandedKeys = dataListHeader
    //   .map((item) => {
    //     if (item.title.indexOf(value) > -1) {
    //       return getParentKey(item.key, dataHeader);
    //     }
    //     return null;
    //   })
    //   .filter((item, i, self) => !!(item && self.indexOf(item) === i));
    // setExpandedKeys(newExpandedKeys);
    setSearchValue(value);
    // setAutoExpandParent(true);
  };
  const treeData = useMemo(() => {
    const loop = (data) =>
      data.map((item) => {
        const strTitle = item.title;
        const cloneString = item.title.toUpperCase();
        const index = cloneString.indexOf(searchValue.toUpperCase());
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="site-tree-search-value" style={{ color: "red" }}>
                {searchValue}
              </span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );
        if (item.children) {
          return {
            title,
            selectable: false,
            checkable: false,
            key: item.key,
            children: loop(item.children),
          };
        }
        return {
          title,
          selectable: false,
          key: item.key,
        };
      });
    return loop(listHeader);
  }, [searchValue]);
  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
    onChangeShowHeader(checkedKeysValue);
  };
  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };
  useEffect(() => {
    setCheckedKeys(dataHeader);
  }, [dataHeader]);
  return (
    <div>
      <Search
        style={{
          marginBottom: 8,
        }}
        placeholder="Search"
        onChange={onChange}
      />
      <Tree
        checkable
        checkedKeys={checkedKeys}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        treeData={treeData}
        onCheck={onCheck}
      />
    </div>
  );
};

const ModalSettingsHeaderTable = ({
  isModalSettings,
  toggle,
  dataHeader = [],
  listHeader = [],
  dataListHeader = [],
  onChangeHeader = () => {},
  onChangeShowHeader = () => {},
  headerDefault = [],
}) => {
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);
  const [newDataHeader, setNewDataHeader] = useState([]);

  useEffect(() => {
    setNewDataHeader(dataHeader);
  }, [dataHeader]);
  return (
    <ModalContainer
      title={t("Display Customizer")}
      isOpen={isModalSettings}
      toggle={toggle}
      size="lg"
      scrollable={true}
      actionCustom={
        <>
          <ButtonTheme
            type="button"
            loadShowText={true}
            loading={loadingButton}
            className="btn btn-primary w-sm"
            onClick={async () => {
              onChangeHeader(headerDefault);
              toggle();
            }}
          >
            {t("Back to default")}
          </ButtonTheme>
          <ButtonTheme type="button" className="btn btn-light w-sm" onClick={toggle}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme
            type="button"
            loadShowText={true}
            loading={loadingButton}
            className="btn btn-primary w-sm"
            onClick={async () => {
              onChangeHeader(newDataHeader);
              toggle();
            }}
          >
            {t("Confirm")}
          </ButtonTheme>
        </>
      }
    >
      <Row>
        <Col lg={6}>
          <Card>
            <CardBody>
              <h4>{t("Add new column")}</h4>
              <ViewFullHeaderTable
                dataHeader={newDataHeader}
                listHeader={listHeader}
                dataListHeader={dataListHeader}
                onChangeShowHeader={(data) => {
                  setNewDataHeader(data);
                }}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardBody>
              <h4>{t("Display column")}</h4>
              {/* <DropDargHeaderTable
                dataHeader={newDataHeader}
                dataListHeader={dataListHeader}
                onDragDropColumn={(data) => {
                  setNewDataHeader(data);
                }}
              /> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ModalContainer>
  );
};

export default withRouter(TableContainerCustom);
